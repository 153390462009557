.pageContact {
  .location {
    @media (min-width: 1400px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  .mainContent {
    .titleSection {
      text-transform: uppercase;
    }

    .item {
      .textWrap {
        .textBox {
          a {
            // color: #003852;
            // font-size:14px;
            color: #000;
          }
        }
      }

      .icon {
        width: 50px;

        img {
          width: 50px;
        }
      }

      .text {
        p {
          padding-top: 2px !important;
          text-align: left !important;
          font-size: 15px !important;
        }
        p + p {
          padding-top: 2px;
        }
      }
    }

    .formContact {
      .formWrap {
        display: block;
      }
    }
  }
}
