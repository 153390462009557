body {
  // opacity:0;
  transition: 0.1s;
}

body.fadeIn {
  opacity: 1;
}

.page.noSlider {
  margin-top: 30px;

  @media (min-width: 768px) {
    margin-top: 50px;
  }

  @media(min-width:992px){
    margin-top: 75px;
  }
}

input.border-danger,
select.border-danger{
  border-color:#dc3545 !important;
  border:1px solid #dc3545 !important;
}

.p-relative {
  position: relative;
}

.mainContainer {
  max-width: 1400px;
  margin: 0 auto;

  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (min-width: 1200px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  &--full {
    max-width: inherit;
    width: 100%;
    max-width: 1700px;
  }
}

.bold {
}

a {
  color: $darkBlue;
}

/* BACKGROUND */

.bgPrimary {
  background-color: #efece8;
}

.darkBlueBg {
  background-color: $darkBlue;
}

p {
  font-size: 15px;
  font-weight: 300;
}

@media (min-width: 768px) {
  p {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  p {
    font-size: 17px;
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: relative;
}

.psuedo-background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.titleSection {
  color: $darkBlue;
  font-size: 48px;
  text-transform: uppercase;

  @include media(xsmall) {
    font-size: 50px;
  }

  @include media(small) {
    font-size: 51px;
  }

  @include media(medium) {
    font-size: 52px;
  }

  @include media(large) {
    font-size: 54px;
  }

  @include media(xlarge) {
    font-size: 60px;
  }

  @include media(xxlarge) {
    font-size: 60px;
  }

  &--medium {
    font-size: 38px;
    @include media(xsmall) {
      font-size: 40px;
    }

    @include media(small) {
      font-size: 42px;
    }

    @include media(medium) {
      font-size: 44px;
    }

    @include media(large) {
      font-size: 46px;
    }

    @include media(xlarge) {
      font-size: 48px;
    }

    @include media(xxlarge) {
      font-size: 50px;
    }
  }

  &--small {
    font-size: 26px;

    @include media(xsmall) {
      font-size: 28px;
    }

    @include media(small) {
      font-size: 30px;
    }

    @include media(medium) {
      font-size: 32px;
    }

    @include media(large) {
      font-size: 34px;
    }

    @include media(xxlarge) {
      font-size: 36px;
    }
  }

  &--xsmall {
    font-size: 20px;

    @include media(xsmall) {
      font-size: 22px;
    }

    @include media(medium) {
      font-size: 24px;
    }
  }

  &--black {
    color: black;
  }
}

.owl-item.active.center {
  transform: scale(1.1, 1.2);
}

.owl-carousel .owl-nav.disabled {
  display: none !important;
}

div.wpcf7 .wpcf7-submit:disabled {
  opacity: 0.7;
}
