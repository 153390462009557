.header {
  transition: 0.3s;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;

  @media (min-width: 992px) {
    background-color: transparent;
  }

  .headerTop {
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (min-width: 576px) {
      justify-content: space-between;
    }

    .leftPanel {
      display: none;
      @media (min-width: 576px) {
        display: flex;
      }
    }

    .rightPanel {
    }
  }

  //// HEADER FIXED

  // &--fixed {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: 100;
  //   background-color: rgba(0, 45, 114, 0.8);
  // }
  //// END HEADER FIXED

  .navbar-brand {
    &__logo {
      width: 100px;
      height: auto;

      @media (min-width: 576px) {
        width: 110px;
      }

      @media (min-width: 768px) {
      }

      @media (min-width: 992px) {
        width: 115px;
      }

      @media (min-width: 992px) {
        width: 130px;
      }

      @media (min-width: 1400px) {
        width: 140px;
      }
    }
  }
}
