.headerBottom {
  .navbar {
    .navbar-nav {
      width: 100%;

      justify-content: center;
      flex-direction: row;
      border-top: 1px solid #fff;

      display: none;

      @media (min-width: 768px) {
        display: flex;
      }

      .nav-item {
        .nav-link {
          padding: 10px 0 7px 0;
          margin: 5px 10px;
          color: #fff;
          text-transform: uppercase;
          font-size: 14px;
        }

        .hvr-underline-from-center:before {
          background: #fff;
          height: 1px;
        }
      }
    }
  }
}
