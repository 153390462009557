$yellow: #e35205;

$lightBlue: #0f3959;
$heavenly: #0f3959;
$orange: #e35205;
$lightGray: #e7e6e5;

$blue: #0f3959;

//new
$darkBlue: #0f3959;
