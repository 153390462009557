.socialIcons {
  img {
    width: 20px;
    height: 20px;
    margin: 4px;
  }

  &--big {
    img {
      width: 30px;
      height: 30px;
      margin-left: 4px;
    }
  }
}
