.mobileBottomPanel{
    width:100%;
    position:fixed;
    bottom:0;
    left:0;
    z-index: 3000;
    background-color:$darkBlue;
    display:none;
    padding:0;

    .item{
        background-color: $darkBlue;
        padding:7px;
        text-align: center;
        width:100%;


        &:hover{
            cursor:pointer;
            opacity:.7;
        }


        img{
            max-width: 18px;
        }
        p{
            color:#fff;
            font-size:10px;
            margin-top:3px;
            text-transform: uppercase;
        }

        &.book{
            background-color:$orange;
            
            p{
                color:$blue;
            }
        }

    }
}