footer {
  padding-top: 10px;
  padding-bottom: 50px;

  @media (min-width: 768px) {
    padding-top: 50px;
  }

  a {
    color: #000;

    @include on-event {
      color: #000;
    }
  }

  /* footerTop*/
  .footerTop {
    background-color: #fff;
    color: #000;
    padding: 25px 0;

    p {
      font-size: 13px;
      @media (min-width: 576px) {
        font-size: 14px;
      }
    }

    .textBox {
      color: #fff;
      font-size: 12px;
      padding: 0;

      @media (min-width: 576px) {
        font-size: 14px;
      }

      p {
        font-size: 13px;
        color: #fff;

        @media (min-width: 576px) {
          font-size: 14px;
        }
      }

      a {
        color: #000;

        @include on-event {
          color: #000;
        }
      }

      p + p {
        padding-top: 8px;
      }

      .small {
        font-size: 10px;
        margin-top: 2px;
      }
    }
  }
  /* ./footerTop*/

  .column {
    border-left: 2px solid #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;

    @include media(small) {
      padding-left: 30px;
    }

    // &:nth-child(1){
    //     border-left:2px solid transparent;

    //     @include media(small){
    //         border-left:2px solid #fff;
    //     }
    // }
  }

  /*footer bottom*/
  .copyBox {
    text-align: center;
    color: #000;
    margin-top: 10px;

    .copyBoxInner {
      border-top: 1px solid #000;
      padding-top: 10px;
      padding-bottom: 15px;

      p {
        font-size: 12px;
        @media (min-width: 576px) {
          font-size: 14px;
        }
      }
    }
  }
  /* ./footer bottom*/
}
