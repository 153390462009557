.sliderBtn {
  font-size: 11px;
  background-color: transparent;
  border: 1px solid #fff;
  margin: 20px auto 0 auto;
  color: #fff;
  font-weight: bold;
  padding: 4px 7px;
  position: relative;
  z-index: 10;
  transition: 0.3s;

  &:active,
  &:hover {
    background-color: $blue;
    color: #fff;
    text-decoration: none;
  }

  @media (min-width: 768px) {
    font-size: 13px;
    padding: 5px 10px;
  }
}

.moreBtn {
  background-color: transparent;
  background-size: contain;
    border: 0;
    color: #0f3959;
    width: auto;
    min-width: 110px;
    display: inline-block;
    padding: 11px 10px;
    font-size: 18px;
    background-image: url(../images/btn_arrow.png);
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: bottom;
    font-family: Shadows Into Light Two,cursive;
    font-weight: 400;
    animation-duration: .6s;
    animation-timing-function: ease-out;
   

  @include on-event {
    color: $darkBlue;
    text-decoration: none;
    animation-name: moreBtnArrow;
    animation-fill-mode: forwards;
  }

  &--white {
    color: #fff;
    background-image: url(../images/btn_arrow_white.png);
    @include on-event {
      color: #fff;
    }
  }
}

.bookBtn {
  font-weight: bold;
  background-color: #fff;

  border-radius: 0;

  color: #000;
  border: 1px solid black;
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  text-transform: uppercase;
  margin: 15px auto 15px auto !important;
  width: 200px;
  padding: 20px 15px;
}

.primaryBtn {
  background-color: transparent;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-size: 13px;
  color: #fff;
  padding: 6px 20px;
  margin: 5px;

  @include on-event {
    color: #fff;
    text-decoration: none;
  }

  @media (min-width: 576px) {
    font-size: 14px;
  }

  @media (min-width: 992px) {
    font-size: 14px;
  }
}

.blueBtn {
  background-color: $darkBlue;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-size: 13px;
  color: #fff;
  padding: 6px 20px;
  margin: 5px;

  @include on-event {
    color: #fff;
    text-decoration: none;
  }

  @media (min-width: 576px) {
    font-size: 14px;
  }

  @media (min-width: 992px) {
    font-size: 14px;
  }
}

@keyframes moreBtnArrow {
  // 0% {
  //   background-position-x: 0;
  // }
  // 60% {
  //   background-position-x: 150px;
  //   background-image: none;
  // }
  // 60.1% {
  //   background-position-x: -140px;
  //   background-image: url(../images/btn_arrow.png);
  // }
  // 100% {
  //   background-position-x: 0;
  // }
  0% {
    background-position-x: -140px;
  }
  100% {
    background-position-x: 0;
  }
}
