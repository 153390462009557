.newsletter {
  background-color: #e0d9d1;
  color: #1d1d1b;
  position: relative;
  padding-top: 0;
  padding-bottom: 20px;

  .bg {
    width: 30%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;

    background-image: url(../images/newsletter.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  h5 {
    font-size: 20px;
    padding-bottom: 20px;
    font-size: 38px;
  }

  p {
    font-size: 11px;

    @media (min-width: 576px) {
      font-size: 12px;
    }
  }

  label {
    font-size: 11px;
    line-height: 13px;
    color: #1d1d1b;
  }

  .safe {
    color: #1d1d1b;
    font-size: 12px;
    padding-top: 14px;
    @media (min-width: 576px) {
      font-size: 14px;
    }
  }

  input[type="text"],
  input[type="email"] {
    border-radius: 0 !important;
    border: 0;
    border-bottom: 1px solid #1d1d1b;
    color: #1d1d1b;
    background-color: transparent;
    height: auto;
    padding: 5px 5px 5px 0;
    transition: 0.3s;
    margin-bottom: 5px;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;

    @media (min-width: 992px) {
      font-size: 15px;
    }

    &:focus {
      padding-left: 5px;
      box-shadow: none !important;
      border-color: #1d1d1b;
    }
  }

  div.wpcf7 .ajax-loader {
    position: absolute;
    margin-top: 8px;
  }

  .wpcf7-form-control-wrap {
    position: static;
    width: 100%;

    span.wpcf7-not-valid-tip {
      width: 100%;
      font-size: 12px;

      @media (min-width: 576px) {
        //position: absolute;
      }
    }
  }

  .wpcf7-response-output.wpcf7-display-none.wpcf7-validation-errors,
  div.wpcf7-mail-sent-ng,
  div.wpcf7-aborted {
    border: none;
    color: #f00;
    font-size: 13px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 0px;
  }

  div.wpcf7-mail-sent-ok {
    border: none !important;
    color: #398f14 !important;
    text-align: center !important;
    font-weight: bold !important;
    margin: 10px 0px !important;
  }

  .btnBox {
    input[type="checkbox"] {
      opacity: 0;
    }
  }

  .formWrap {
    @include flex-center;
    background-color: #e0d9d1;
    padding: 25px 0;
    margin-top: 30px;

    h5 {
      color: #1d1d1b;
      text-transform: uppercase;
    }

    input[type="submit"] {
      color: $darkBlue;
    }
  }

  .agree {
    margin-bottom: 10px;
  }
}
