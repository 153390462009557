.slideNav {
  background-color: $blue;
  position: fixed;
  height: 100vh;
  top: 0;
  right: -500px;
  width: 100%;
  max-width: 500px;
  z-index: 100;
  overflow-y: scroll;
  transition: 0.4s;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 450px) {
    max-width: 325px;
  }

  @media (min-width: 1200px) {
    max-width: 400px;
  }

  @media (min-width: 1600px) {
    max-width: 500px;
  }

  @media (min-width: 1900px) {
    max-width: 550px;
    right: -551px;
  }

  @media (min-width: 2000px) {
    right: -601px;
    max-width: 600px;
  }

  &.active {
    right: 0;
  }

  .rightPanel {
  }

  .navbar {
    @include flex-center;

    .navbar-nav {
      .nav-item {
        .nav-link {
          color: #fff;
          font-family: "Amatic SC", cursive;
          padding: 0;

          font-size: 40px;

          @include media(medium) {
            font-size: 40px;
          }

          @include media(large) {
            font-size: 40px;
          }

          @include media(xxlarge) {
            font-size: 50px;
          }
        }
      }
    }
  }
}
