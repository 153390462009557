@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two&display=swap");

//font-family: 'Lato', sans-serif;
//font-family: 'Amatic SC', cursive;
//font-family: 'Shadows Into Light Two', cursive;

body {
  font-family: "Lato", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Amatic SC", cursive;
}

h5 {
  font-size: 38px;
}
